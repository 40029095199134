import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import HeaderLight from "../../components/header/headerLight/headerLight";

import "./style.scss";
import {graphql} from "gatsby";
import BlocContent from "../../components/dynamicZone/blocContent/blocContent";

const IndiceBTP = ({location, data}) => {
  const sections = data.allStrapiIndiceBtp.nodes[0].Sections;

  return (
    <>
      <Helmet>
        <body className="indice-btp"/>
      </Helmet>
      <Layout>
        <SEO title="Indice BTP" description="L'indexation consiste à lier l'évolution des garanties et des cotisations à la variation d'un indice représentatif de la hausse des prix dans un certain domaine: indice du coût de la construction, valeur du point Agirc."/>
        <HeaderLight
          title={
            <>
              Indice <strong>BTP</strong>
            </>
          }
          location={location}
          crumbLabel="Indice BTP"
        />

        <section className="indice-btp__intro">
          <p>Retrouvez ci-dessous tous les indices BTP mis à jour.</p>
        </section>

        {sections.map((bloc, i) => {
          bloc.Images = []
          return <BlocContent key={i} bloc={bloc} />
        })}

      </Layout>
    </>
  );
};

export const query = graphql`
  query IndiceBTPPageQuery {
    allStrapiIndiceBtp {
      nodes {
        Sections {
          Titre
          Contenu {
            data {
              Contenu
            }
          }
        }
      }
    }
  }
`;

export default IndiceBTP;
