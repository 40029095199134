import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import "./blocContent.scss";
import Button from "../../button/button";

export default function BlocContent({ bloc }) {
  bloc.Images = bloc.Images || [];

  const getCouleurDeFond = function () {
      if (bloc.Fond_De_Couleur === true) {
          if (bloc.Couleur_de_fond) {
              return "bg-" + bloc.Couleur_de_fond;
          }
          else {
              return "bloc-content--grey";
          }
      }
      else {
          return "";
      }
  }
    const getCouleurDeFondImage = function () {
        if (bloc.Fond_De_Couleur === true && bloc.Images.length > 0) {
            if (bloc.Couleur_de_fond) {
                return "bloc-content--bg-" + bloc.Couleur_de_fond + "-image";
            }
            else {
                return "bloc-content--grey-image";
            }
        }
        else {
            return "";
        }
    }

  return (
    <section
      className={`
      section-container-block 
        bloc-content
        ${getCouleurDeFond()}
        ${getCouleurDeFondImage()}
        ${bloc.Position_De_L_Image === "Gauche" ? " bloc-content--left" : ""}
        ${bloc.Position_De_L_Image === "Droite" ? " bloc-content--right" : ""}
        ${bloc.Images.length ? " bloc-content--margin" : ""}`}
    >
      <h2>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          children={bloc.Titre ?? ""}
        />

      </h2>

      <ReactMarkdown
        className="bloc-content__markdown"
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        children={bloc.Contenu.data.Contenu}
      />

        {(bloc.Bouton && bloc.Lien) && (
            <div className={'bloc-content-button'}>
                <Button className={'btn-more'} text={bloc.Bouton} to={bloc.Lien}/>
            </div>
        )}

      {bloc.Images && (
        <div
          className={`bloc-content__images bloc-content__images--${bloc.Images.length}`}
        >
          {bloc.Images.map((image, i) => {
            return (
              <GatsbyImage
                image={getImage(image.localFile)}
                alt={`illustration ${bloc.Titre}`}
                key={i}
              />
            );
          })}
        </div>
      )}
    </section>
  );
}
